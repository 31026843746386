import React from 'react';
import { useLocation } from 'react-router-dom';
import img from './img/thank you message.png';
import "./successpage.css"

const Success = () => {
    const getBaseURL = () => {
        const host = window.location.host;
      
        if (host.includes('localhost')) {
          return 'http://localhost:3000'; // Local API URL
        } else {
          return 'https://iyfghaziabad.in'; // Production API URL
          }
        };
    const location = useLocation();
    const promoCode = location.state?.promoCode || '';  // Extract promo code from location state
    const wantsDhotiKurta = location.state?.wantsDhotiKurta || 'no';  // Extract wantsDhotiKurta from location state


    // Normalize wantsDhotiKurta to lowercase for comparison
    const normalizedDhotiKurta = wantsDhotiKurta.toLowerCase();

    // Construct the referral URL
    const referralUrl = `${getBaseURL()}/registration?promo=${promoCode}`;

    // Function to copy referral URL to clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralUrl);
        alert("Referral link copied to clipboard!");
    };

    // Determine the amount to pay
    const amountToPay = normalizedDhotiKurta === 'yes' ? 1000 : 600;

    return (
        <div className='main'>
            <div style={{ padding: '20px', textAlign: 'center' }}>
            <div className='thankimage'>
                {/* Thank You Image */}
                <img
                    src={img}  // Replace with your actual image path
                    alt="Thank you"
                    style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
                />
                </div>

                <h1 className='head'>Thank you for registering for Viplava 2024</h1>
                <h2 className='head2'></h2>
                <div className='inputdiv'>
                    <input
                        type="text"
                        value={referralUrl}
                        readOnly
                        style={{
                            width: '70%',
                            padding: '10px',
                            fontSize: '16px',
                            marginBottom: '10px',
                            border: "1px solid black"
                        }}

                    />
                    <button onClick={copyToClipboard} className='sig-btn'>
                        Copy Referral Link
                    </button>
                </div>
                {/* Additional Line */}
                <p className='amountpaid'>
                    Please submit the cash amount of ₹{amountToPay} to your area coordinator.
                </p>
            </div>
        </div>
    );
};

export default Success;
